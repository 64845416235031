import React from "react";
import { useNavigate } from "react-router-dom";
import { CButton, CCol, CContainer, CRow } from "@coreui/react";
import errorImage from "../../../img/403.png";

const Page403 = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center align-items-center">
          <CCol md={6} className="d-flex flex-column justify-content-center">
            <div className="clearfix">
              <h1 className="display-3 fw-bold">We are sorry...</h1>
              <p
                className="text-body-secondary"
                style={{
                  fontSize: "1.25rem",
                  color: "#6c757d",
                  maxWidth: "431px",
                }}
              >
                The page you are trying to access has restricted access. Please
                refer to your system administrator.
              </p>
              <CButton
                style={{
                  backgroundColor: "red",
                  color: "white",
                  border: "none",
                }}
                className="mt-3"
                onClick={() => navigate(-2)} // Navigates back to the previous route
              >
                Go Back
              </CButton>
            </div>
          </CCol>
          <CCol
            md={6}
            className="d-flex justify-content-center align-items-center"
          >
            <img
              src={errorImage}
              alt="403 Access Denied"
              className="img-fluid"
            />
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Page403;

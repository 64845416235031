// components/GlobalLoader.js
import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const GlobalLoader = () => {
  const { loading } = useContext(AuthContext);

  if (!loading) return null;

  return (
    <div className="global-loader">
      <div className="loader-content">Loading...</div>
    </div>
  );
};

export default GlobalLoader;

/* eslint-disable no-debugger, no-unused-vars */
// eslint-disable-next-line no-debugger

import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import PropTypes from "prop-types";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);

  if (!loading && !isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return children;
};

// Add PropTypes validation
ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired, // Validate children prop
};

export default ProtectedRoute;

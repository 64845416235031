import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true); // Global loading state initially true

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setIsAuthenticated(true);
      setToken(storedToken);
    }
    setLoading(false); // Ensure loading state is set correctly after checking token
  }, []);

  const loginManagement = (newToken) => {
    localStorage.setItem("token", newToken);
    setToken(newToken);
    setIsAuthenticated(true);
  };

  const logoutManagement = () => {
    localStorage.removeItem("token");
    setToken(null);
    setIsAuthenticated(false);
  };
  // Method to update loading state
  const setLoadingState = (state) => {
    setLoading(state);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        token,
        loading,
        setLoadingState,
        loginManagement,
        logoutManagement,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthContext, AuthProvider };
